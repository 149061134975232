$checkbox-width: 17px;
$checkbox-height: 17px;

.checkbox {
    position: relative;

    > label {
        margin-bottom: 0;

        > span {
            display: inline-block;
            vertical-align: middle;
            width: $checkbox-width;
            height: $checkbox-height;
            cursor: pointer;
        }
    }

    input[type="checkbox"] {
        display: none;

        & + .checkbox__box {
            &:before {
                transition: all .3s;
            }
            &:after {
                transition: all .3s;
                position: absolute;
                left: 0;
                display: inline-block;
                content: ' ';
                width: $checkbox-width;
                height: $checkbox-height;
                border: 2px solid $brand-color;
                border-radius: 2px;
                background-color: #fff;
            }
        }

        &:checked + .checkbox__box {
            &:before {
                transform: rotate(45deg);
                position: absolute;
                left: 3px;
                margin-top: 2px;
                margin-left: 3px;
                width: 6px;
                height: 10px;
                border-width: 2px;
                border-style: solid;
                border-top: 0;
                border-left: 0;
                border-color: #fff;
                content: '';
                z-index: 1;
            }
            &:after {
                background-color: $brand-color;
                border-color: $brand-color;
            }
        }

        &:checked:disabled + .checkbox__box {
            &:before {
                border-color: $gray-500;
            }
        }

        &:indeterminate + .checkbox__box {
            &:before {
                content: '';
                position: absolute;
                left: 3px;
                top: 9px;
                width: 11px;
                height: 2px;
                z-index: 1;
                background-color: white;
            }
            &:after {
                background-color: $brand-color;
            }
        }

        &:disabled + .checkbox__box {
            cursor: not-allowed;

            &:after {
                border-color: $gray-500;
                background-color: $gray-200;
            }
        }
    }
}