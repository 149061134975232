.form-control {
    border-width: 0 0 1px 0;

    &:focus,
    &.focus {
        border-width: 0 0 2px 0;
        border-color: $brand-color;
    }

    &:disabled {
        cursor: not-allowed;
    }
}

.form-group {
    label {
        font-weight: bold;
    }
}

.input-group-text {
    border-width: 0 0 1px 0;
}

.list-group-item {
    &:first-child {
        border-top: 0;
    }

    &:last-child {
        border-bottom: 0;
    }
}

.btn {
    text-transform: uppercase;
    font-weight: bold;
}

.btn-global,
a.btn-global {
    color: #fff;
    background-color: $brand-color;
    border-color: $brand-color;

    &:hover {
        color: #fff;
        background-color: $brand-color--darken-7;
        border-color: $brand-color--darken-10;
    }

    &.disabled,
    &:disabled {
        color: #fff;
        background-color: $brand-color;
        border-color: $brand-color;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > .btn-global.dropdown-toggle {
        color: #fff;
        background-color: $brand-color;
        border-color: $brand-color;
    }

}

.badge-global {
    color: #FFF;
    background-color: $brand-color;

    @at-root a#{&} {
        @include hover-focus() {
            color: #FFF;
            background-color: $brand-color--darken-10;
        }

        &:focus,
        &.focus {
            outline: 0;
            box-shadow: 0 0 0 $badge-focus-width rgba($brand-color, .5);
        }
    }
}

.spinner {
    &-border {
        height: 75px;
        width: 75px;
        color: $brand-color;
        animation: spinner-border 1.5s linear infinite;
        border: 0.4em solid currentColor;
        border-right-color: transparent;
    }
}

.background-header {
    $color: #575757;

    &--success {
        color: $color;
        background-color: #d9f2e6;
    }

    &--warning {
        color: $color;
        background-color: #fdf0dc;
    }

    &--danger {
        color: $color;
        background-color: #fae4e1;
    }
}

.brand-color {
    color: $brand-color;
}

input::placeholder {
    color: #999 !important;
}