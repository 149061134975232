$default-brand-color: #bc2e39;
$default-background-color: #EBEBEB;
$default-text-color: #8d8d8d;

:root {
    --background-color: $default-background-color;
    --background-color--accent: #EEE;
    --background-color--stamp: #{darken($default-brand-color, 7%)};
    --brand-color: #{$default-brand-color};
    --brand-color--darken-7: #{darken($default-brand-color, 7%)};
    --brand-color--darken-10: #{darken($default-brand-color, 10%)};
    --brand-color--darken-12: #{darken($default-brand-color, 12%)};
    --brand-color--darken-50: #{darken($default-brand-color, 50%)};
    --brand-color--lighten-7: #{lighten($default-brand-color, 7%)};
    --brand-color--lighten-10: #{lighten($default-brand-color, 10%)};
    --brand-color--lighten-12: #{lighten($default-brand-color, 12%)};
    --brand-color--lighten-50: #{lighten($default-brand-color, 50%)};
    --brand-color--opacity-50: #{opacify($default-brand-color, 0.5)};
    --badge-text-color: $default-text-color;
    --text-color: $default-text-color;

    --zoom: 1;
    --zoom-inverse: 1;
    --ratio: 1;
}

$background-color: var(--background-color);
$background-image: var(--background-image);

$background-color--accent: var(--background-color--accent);
$background-color--stamp: var(--background-color--stamp);

$badge-text-color: var(--badge-text-color);
$text-color: var(--text-color);

$brand-color: var(--brand-color);
$brand-color--darken-7: var(--brand-color--darken-7);
$brand-color--darken-10: var(--brand-color--darken-10);
$brand-color--darken-12: var(--brand-color--darken-12);
$brand-color--darken-50: var(--brand-color--darken-50);
$brand-color--lighten-7: var(--brand-color--lighten-7);
$brand-color--lighten-10: var(--brand-color--lighten-10);
$brand-color--lighten-12: var(--brand-color--lighten-12);
$brand-color--lighten-50: var(--brand-color--lighten-50);
$brand-color--opacity-50: var(--brand-color--opacity-50);

$header-height: 60px;

$ui-hover-color: #848484;
$input-background-color: #ffffff;

$panel-nav-width: 80px;
$page-title-height: 57px;
$page-padding: 15px;

$btn-edit-color: #3c8dbc;
$btn-export-color: #ffffff;

:export {
    defaultBrandColor: $default-brand-color;
    defaultBackgroundColor: $default-background-color;
    defaultTextColor: $default-text-color;
}