@import "../config/config";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";

.btn {
    &:disabled, &.disabled {
        cursor: not-allowed;
    }

    &.first-letter-capital {
        text-transform: lowercase !important;

        &:first-letter {
            text-transform: uppercase;
        }
    }

    &[data-is-list="false"]::after {
        content: none;
    }

    &--save {
        @extend .btn-success;
    }

    &--global {
        @extend .btn-global;
    }

    &--delete {
        @extend .btn-danger;
    }

    &--light {
        @extend .btn-light;
    }

    &--edit {
        color: $white;
        background-color: $btn-edit-color;
        border-color: $btn-edit-color;

        &:hover {
            color: $white;
            background-color: #{darken($btn-edit-color, 7%)};
            border-color: #{darken($btn-edit-color, 10%)};
        }
    }

    &--export {
        color: $badge-text-color;
        background-color: $btn-export-color;
        border-color: $btn-export-color;

        &:hover {
            color: $badge-text-color;
            background-color: #{darken($btn-export-color, 7%)};
            border-color: #{darken($btn-export-color, 10%)};
        }
    }

    &--return,
    &--reset {
        @extend .btn-light;
    }

    &.slashed {
        position: relative;

        .fa-slash {
            position: absolute;
            left: 6px;
            top: 6px;
            font-size: 1.2em;
        }
    }
}

.btn-group-toggle {
    .btn-success:not(.active):not(.toggled),
    .btn-danger:not(.active):not(.toggled) {
        @extend .btn-light;
    }
}

.btn.dropdown-toggle:not(.ignore-default) {
    &.dropdown-toggle-split {
        margin-left: -2px;
    }

    + .dropdown-menu.show {
        left: 7px !important;

        &.dropdown-menu-right {
            left: 17px !important;
        }

        .dropdown-item {
            padding: 0.5rem 1.5rem;

            &:not(:first-of-type) {
                border-left: 1px solid rgba(0, 0, 0, 0.15);
            }

            &:active {
                color: inherit;
                background-color: inherit;
            }
        }
    }
}

.input-group-append {
    .dropdown-item {
        text-transform: none;
    }
}