.vertical-center {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    align-content: center;
}

.disabled-div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: 0.7;
    z-index: 10;
}