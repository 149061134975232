.ui-language {
    &__link {
        img {
            width: 30px;
            margin-top: 5px;
            filter: grayscale(100%);
        }

        &.is-active,
        &:hover {
            border-bottom: none;
            text-decoration: none;

            img {
                filter: grayscale(0);
            }
        }
    }
}
