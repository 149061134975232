@import "config/config";

$input-border-color: $brand-color;
$input-focus-border-color: $brand-color;

@import "config/bootstrap";

@import "components/reset";
@import "components/util";
@import "components/translations";
@import "components/checkmark";
@import "components/button";

html {
    height: 100%;
}

body {
    font-size: 12px;
    background: url("../images/login_bkg.png") left top / cover $brand-color;
    height: 80%;
}

#logo {
    height: 90px;
    width: 65%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
}

.login-box {
    width: 460px;
    padding: 25px 40px 1px;
    background-color: $input-background-color;
    box-shadow: 0 0 40px rgba($brand-color--darken-50, 0.1);

    #loginForm {
        input, #submit, #azure-oauth {
            margin: 0 0 2em;
        }

        .password-plain-text {
            margin: 0 0 2em;
            font-size: 16px;
        }
    }

    input {
        &.form-control {
            font-size: 16px;
            text-align: left;
            background-color: #fff;
            border-width: 0 0 1px 0;
            padding-left: 0;
            line-height: 1em;

            &:focus {
                background-color: #fff !important;
                border-width: 0 0 2px 0;
                box-shadow: none;
            }

            &.is-invalid {
                color: $red;
                border-color: $red;
            }
        }

        &:-webkit-autofill {
            &#{""},
            &:hover,
            &:focus,
            &:active {
                -webkit-box-shadow: 0 0 0 30px white inset;
            }
        }

    }

    a.link {
        color: $brand-color;
        &:hover {
            font-weight: bold;
        }
    }

    h2 {
        font-size: 2em;
        color: $gray-600;
    }

    #two-auth-click-here {
        cursor: pointer;
        color: $brand-color;
    }
}

#version {
    position: absolute;
    right: 15px;
    bottom: 15px;
}

@media screen and (max-height: 815px) {
    #login-img {
        margin-top: 60px;
    }
}

@media screen and (max-height: 800px) {
    body {
        height: 100%;
    }
}